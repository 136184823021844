<template>
  <v-card>
    <v-card-title class="px-0 pt-0">
      <v-toolbar flat>
        <h2 class="title">{{ $t('recipe.medicine_information') }}</h2>
        <v-spacer />
        <v-btn v-tooltip="$t('common.close')" icon @click="$emit('close')">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>
    </v-card-title>
    <v-card-text class="px-8">
      <template v-if="isLoading">
        <v-row justify="center" class="py-10">
          <v-progress-circular indeterminate color="primary" />
        </v-row>
      </template>

      <template v-else>
        <v-row dense>
          <v-col class="text-h6">
            <span class="black--text">{{ medicineDetails.nombre.trim() }}, {{ formattedUnits }}</span>
            <span> ({{ medicine.nationalCode }})</span>
          </v-col>
        </v-row>
        <v-row dense>
          <v-col class="text-subtitle-1">{{ medicineDetails.labtitular }}</v-col>
        </v-row>

        <v-card outlined class="mt-4 mb-6">
          <v-card-text>
            <v-row dense>
              <v-col cols="12" md="6">
                <div>
                  <span class="text-uppercase font-weight-bold">{{ $t('recipe.registration_number') }}:</span>
                  {{ medicineDetails.nregistro }}
                </div>
                <div>
                  <span class="text-uppercase font-weight-bold">{{ $t('recipe.authorized') }}:</span>
                  {{ authorizedDate }}
                </div>
                <div v-if="revokedDate">
                  <span class="text-uppercase font-weight-bold">{{ $t('recipe.revoked') }}:</span>
                  {{ revokedDate }}
                </div>
                <div v-if="discontinuedDate">
                  <span class="text-uppercase font-weight-bold">{{ $t('recipe.discontinued') }}:</span>
                  {{ discontinuedDate }}
                </div>
              </v-col>
              <v-col :align="$vuetify.breakpoint.mdAndUp ? 'end' : 'center'" align-self="end" cols="12" md="6">
                <v-btn v-if="medicinePackageLeafletUrl" text color="primary" @click="openPackageLeaflet">
                  {{ $t('recipe.package_leaflet') }}
                </v-btn>
                <v-btn v-if="medicineTechnicalSheetUrl" text color="primary" class="ml-3" @click="openTechnicalSheet">
                  {{ $t('recipe.technical_sheet') }}
                </v-btn>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>

        <v-row v-if="medicineDetails.vtm.nombre" dense class="details-row">
          <v-col class="details-row__title" cols="12">
            {{ $t('recipe.active_ingredient') }}
          </v-col>
          <v-col class="details-row__description" cols="12">
            {{ medicineDetails.vtm.nombre }}
          </v-col>
        </v-row>

        <v-row v-if="medicineDetails.dosis" dense class="details-row">
          <v-col class="details-row__title" cols="12">
            {{ $t('recipe.dosage') }}
          </v-col>
          <v-col class="details-row__description" cols="12">
            {{ medicineDetails.dosis }}
          </v-col>
        </v-row>
      </template>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  name: 'MedicineDetails',
  props: {
    medicine: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      medicineDetails: null,
      isLoading: false,
    };
  },
  computed: {
    formattedUnits() {
      return `${this.medicine.unitAmount} ${this.medicine.unitType.name}`;
    },
    authorizedDate() {
      return this.medicineDetails?.estado?.aut
        ? this.$moment(this.medicineDetails.estado.aut).format('DD/MM/YYYY')
        : '';
    },
    revokedDate() {
      return this.medicineDetails?.estado?.rev
        ? this.$moment(this.medicineDetails.estado.rev).format('DD/MM/YYYY')
        : '';
    },
    discontinuedDate() {
      return this.medicineDetails?.estado?.susp
        ? this.$moment(this.medicineDetails.estado.susp).format('DD/MM/YYYY')
        : '';
    },
    medicineTechnicalSheetUrl() {
      if (this.medicineDetails?.docs) {
        const documentFound = this.medicineDetails.docs.find(doc => doc.tipo === 1);
        if (documentFound) {
          return documentFound.url;
        }
      }
      return null;
    },
    medicinePackageLeafletUrl() {
      if (this.medicineDetails?.docs) {
        const documentFound = this.medicineDetails.docs.find(doc => doc.tipo === 2);
        if (documentFound) {
          return documentFound.url;
        }
      }
      return null;
    },
  },
  watch: {
    'medicine.nationalCode': {
      handler(nationalCode) {
        this.getMedicineDetailsByNationalCode(nationalCode);
      },
      immediate: true,
    },
  },
  methods: {
    openPackageLeaflet() {
      window.open(this.medicinePackageLeafletUrl, '_blank');
    },
    openTechnicalSheet() {
      window.open(this.medicineTechnicalSheetUrl, '_blank');
    },
    async getMedicineDetailsByNationalCode(nationalCode) {
      try {
        this.isLoading = true;

        const medicineDetailsUrl = `https://cima.aemps.es/cima/rest/medicamentos?cn=${nationalCode}`;
        const response = await this.$http.get(medicineDetailsUrl);
        this.medicineDetails = response.body.resultados[0];
      } catch (err) {
        const textAlert = err.data?.error ? this.$t(err.data.error) : this.$t('errors.try_again');
        this.$toast.error(textAlert);
      } finally {
        this.isLoading = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.details-row {
  padding-bottom: 1rem;

  &__title {
    font-size: 110%;
    font-weight: 600;
    color: rgba(0, 0, 0, 0.85);
  }
}
</style>
